import { ArticleLink, ArticlesByDate } from 'sharedTypes';
import { parse, format } from 'date-fns';

export const groupArticlesByDate = (
  articles: ArticleLink[]
): ArticlesByDate => {
  return articles.reduce(
    (articlesByDate: ArticlesByDate, article: ArticleLink) => {
      const { releaseDate } = article;
      const day = format(new Date(releaseDate), 'LLLL dd yyyy');
      const dayAsDate: Date = parse(day, 'LLLL dd yyyy', new Date());
      const dateString = dayAsDate.toISOString();

      articlesByDate[dateString] = !!articlesByDate[dateString]
        ? articlesByDate[dateString].concat([article])
        : [article];

      return articlesByDate;
    },
    {}
  );
};
