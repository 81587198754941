import { compareAsc, subDays, format } from 'date-fns';
import { Button } from 'components/base';
import AuthorButtons from 'components/AuthorButtons';
import Language from 'constants/Language';
import generateArticleUrl from 'utils/generateArticleUrl';
import { ArticlesByDate } from 'sharedTypes';
import { curlyQuotes } from 'utils/text';

interface StoreProps {
  articlesByDate: ArticlesByDate;
}

const yesterday = subDays(new Date(), 1);

const LatestArticles: React.FC<StoreProps> = ({ articlesByDate }) => (
  <div className="LatestArticles inner-content-max-width content-padding-x mxauto">
    <div className="LatestArticles__container border-top-black md:pt1">
      <div className="LatestArticles__sections w100">
        {Object.entries(articlesByDate)
          .slice(0, 4)
          .map(([timestamp, articles]) => {
            const date: Date = new Date(timestamp);
            const isYesterday =
              format(yesterday, 'LLLL dd yyyy') ===
              format(date, 'LLLL dd yyyy');

            if (compareAsc(date, new Date(yesterday)) > 0) return null;

            return (
              <div
                key={timestamp}
                className="LatestArticles__section mb1_25 pr1_25 border-bottom-black"
              >
                <div className="LatestArticles__section__date md:flex md:items-baseline graebenbach">
                  <div className="LatestArticles__section__date__title mb_75 font-800">
                    {isYesterday
                      ? Language.t('Global.yesterday')
                      : format(date, 'EEEE')}
                    ’s&nbsp;
                    {Language.t('Global.stories')}
                  </div>
                  <div className="LatestArticles__section__date__day">
                    {format(date, 'LLLL dd, yyyy')}
                  </div>
                </div>
                <div className="LatestArticles__section__stories flex mb_75 pb1 md:block md:mr0 dotted-border-black md:border-top-black">
                  {articles.map((article) => (
                    <div
                      key={article.slug}
                      className="LatestArticles__section__story px1_5 md:px0 trigger-LatestArticle-ArticleLink "
                    >
                      <Button
                        ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                          title: article.title,
                        })}
                        to={generateArticleUrl(article)}
                        containerClassName="block-important"
                        wrap={true}
                      >
                        <span className="LatestArticles__section__story__title itc-cushing color-black font-600 linked-border-bottom-sm-on-hover-red">
                          {curlyQuotes(article.title)}
                        </span>
                      </Button>
                      <div className="LatestArticles__section__story__dek mb1_5 color-gray-darker graebenbach font-400">
                        {curlyQuotes(article.dek)}
                      </div>
                      <AuthorButtons
                        className="LatestArticles__section__story__authors block color-black"
                        authors={article.authors}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  </div>
);

export default LatestArticles;
