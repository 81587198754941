import { HomeView } from '../views/HomeView';
import { getPreviewParam, withServerSideBaseData } from 'utils/next';
import { ArticleLink, FrontPage, HolidayBanner } from 'sharedTypes';
import ApiClient from 'lib/ApiClient';

interface HomeProps {
  frontPage: FrontPage;
  holidayBanner?: HolidayBanner;
}

const Home: React.FC<HomeProps> = ({
  frontPage,
  holidayBanner = {
    _type: 'holiday',
    id: '',
    slug: '',
    holidayBanner: undefined,
  },
}) => {
  return <HomeView frontPage={frontPage} holidayBanner={holidayBanner} />;
};

export const getServerSideProps = withServerSideBaseData(async (ctx) => {
  const paramBasedRedirect = ctx?.params?.cat;
  const previewId = getPreviewParam(ctx, '/');
  const frontPage = await ApiClient.fetchFrontPage(previewId);
  let holidayBanner;

  if (frontPage?.holiday) {
    const holidayId = frontPage.holiday._ref;

    holidayBanner = await ApiClient.fetchHolidayBannerById(holidayId);
  }

  return {
    ...(paramBasedRedirect && {
      redirect: {
        destination: `/cat=${paramBasedRedirect}`,
        permanent: false,
      },
    }),
    props: { frontPage, holidayBanner },
    notFound: !frontPage,
  };
});

export default Home;
