import React from 'react';
import cx from 'classnames';
import generateSectionUrl from '../utils/generateSectionUrl';
import { Img, Button } from '../components/base';
import Language from '../constants/Language';
import { Section, SectionLink, SectionMenuLink } from '../sharedTypes';
import { Theme } from '../types';
import { useUI } from '../providers/UIProvider';
import sanityImgUtil from '../utils/sanityImgUtil';
import { RouteMap } from '../constants/RouteMap';

interface Props {
  section?: Section | SectionLink | SectionMenuLink;
  children?: React.ReactNode;
  isSectionNav?: boolean;
  showArchiveLink?: boolean;
  className?: string;
}

export const TopBar: React.FC<Props> = (props) => {
  const { theme } = useUI();
  const { section, children, isSectionNav, showArchiveLink, className } = props;
  const sectionClassname = section?.slug
    ? `TopBar__section--${section.slug}`
    : null;

  const isFoodSection = section?.slug === 'food';
  const isArchivedSectionPage = showArchiveLink && section?.slug !== 'holidays';

  return (
    <div
      className={cx('TopBar', className, sectionClassname, {
        'content-with-nav': isSectionNav,
      })}
    >
      {children && children}
      {section && (
        <div
          className={cx('TopBar__container flex flex-col items-center', {
            'border-bottom-black':
              section.slug === 'holidays'
                ? undefined
                : theme === Theme.Default || theme === Theme.Beige,
            'border-bottom-gray-darker': theme === Theme.Black,
          })}
        >
          {isFoodSection && (
            <Button
              className="TopBar__section--food-recipes-link color-black graebenbach pointer move-image-right-on-hover"
              to="/recipes"
            >
              Explore recipes
              <div className="inline-block ml_25 button-move-arrow">→︎</div>
            </Button>
          )}
          <Button
            to={generateSectionUrl(section.slug)}
            ariaLabel={Language.t('Global.sectionButtonAriaLabel', {
              sectionTitle: section.title,
            })}
          >
            <Img
              className="TopBar__section-logo"
              src={sanityImgUtil(section.images.dark)}
              alt={section.images.dark?.alt || section.title}
            />
          </Button>
          {isArchivedSectionPage && (
            <Button
              className="TopBar__section--archive color-black graebenbach pointer move-image-right-on-hover"
              to={`${RouteMap.ARCHIVE.base}${section.slug}/archive`}
            >
              Archive
              <div className="inline-block ml_25 button-move-arrow">→︎</div>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default TopBar;
