import { HebrewDate } from 'types';

/**
 * Exports the hebrew date as parsed by the browser.
 * This is used as a fallback when Hebcal does not respond.
 */
export const getHebrewDateLocal = (): HebrewDate => {
  const hebrewDateString = new Date().toLocaleDateString('en-US-u-ca-hebrew');
  const [day, month, year] = hebrewDateString.split(' ');
  return {
    year,
    month,
    day,
  };
};
