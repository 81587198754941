import Language from '../constants/Language';
import HomepageJsonLd from '../constants/HomepageJsonLd';
import HomeNav from '../components/HomeNav';
import LayoutModuleSwitch from '../components/LayoutModuleSwitch';
import HomeSidebar from '../components/HomeSidebar';
import ExploreScrollButton from '../components/ExploreScrollButton';
import LatestArticles from '../components/LatestArticles';
import Meta from '../components/Meta';
import * as FeatureFlags from '../utils/featureFlags';
import { ArticleLink, FrontPage, HolidayBanner } from '../sharedTypes';
import { groupArticlesByDate } from '../utils/articles';
import { useSiteSettings } from '../providers/SiteSettingsProvider';
import { useUI } from '../providers/UIProvider';
import React, { useMemo } from 'react';
import { hasLatestStoriesFeatureFlag } from 'utils/next';

interface HomeViewProps {
  frontPage: FrontPage;
  holidayBanner?: HolidayBanner;
}

export const HomeView: React.FC<HomeViewProps> = (props) => {
  const { openSectionNav } = useUI();
  const { globalSettings, latestArticles } = useSiteSettings();
  const { sectionScrollCTA } = globalSettings;
  const { frontPage, holidayBanner } = props;

  const articlesByDate = useMemo(() => {
    if (latestArticles) {
      return groupArticlesByDate(latestArticles);
    }
  }, [latestArticles]);

  return (
    <div className="HomeView view content-height relative md:pb1_5">
      <Meta
        title="Tablet Magazine"
        description="A hub of Jewish life, Tablet features news, essays, podcasts, and opinion, covering arts, pop culture, technology, holidays, sports, and more."
        jsonLd={HomepageJsonLd}
      />
      <h1 className="safe-visibility-hidden">{Language.t('Global.slogan')}</h1>
      {frontPage.featuredImage?.src ? (
        <HomeNav
          featuredImage={frontPage.featuredImage}
          featuredLink={frontPage.featuredLink ? frontPage.featuredLink : '/'}
          showTagline={frontPage.showTagline}
          taglineText={frontPage.taglineText}
          showAppButton={frontPage.showAppButton}
        />
      ) : holidayBanner && holidayBanner.id ? (
        <HomeNav
          holidayBanner={holidayBanner}
          showTagline={frontPage.showTagline}
          taglineText={frontPage.taglineText}
          showAppButton={frontPage.showAppButton}
        />
      ) : (
        <HomeNav
          showTagline={frontPage.showTagline}
          taglineText={frontPage.taglineText}
          showAppButton={frontPage.showAppButton}
        />
      )}
      <LayoutModuleSwitch modules={frontPage.modules} />

      {hasLatestStoriesFeatureFlag && articlesByDate ? (
        <LatestArticles articlesByDate={articlesByDate} />
      ) : null}

      <HomeSidebar
        sectionScrollCTA={sectionScrollCTA}
        openSectionNav={openSectionNav}
      />

      <ExploreScrollButton className="md:none" theme="dark" />
    </div>
  );
};
